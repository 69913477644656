import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompleteForm, formatPhoneNumber } from "../../helper/YupResolvers";
import { useForm } from "react-hook-form";
import {
  ContactFormData,
  useAppointment,
} from "../../helper/AppointmentContext";
import Loader from "../Loader";
import PromoCode from "./PromoCode";
import SquareLogo from "../../assets/img/square_logo.png";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";

interface PaymentFormProps {
  setIsConfirmation: (finalStep: boolean) => void;
}

const AppointmentPaymentForm: React.FC<PaymentFormProps> = ({
  setIsConfirmation,
}) => {
  const { state, updateAppointment } = useAppointment();
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const {
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CompleteForm),
  });

  const onSubmitHandler = ({ token, buyer }: any) => {
    setIsLoading(true);
    const values = getValues();

    for (const [key, value] of Object.entries(values)) {
      if (!value) {
        setError(key as keyof ContactFormData, {
          type: 'manual',
          message: `${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')} is required`,
        });
        setHasErrors(true);
        setIsLoading(false);
      } else {
        setHasErrors(false);
      }
     }
     if (!hasErrors) {
       for (const key in values) {
          if (values.hasOwnProperty(key)) {
          updateAppointment(
            key as keyof ContactFormData,
            values[key as keyof ContactFormData]
          );
        }
      }
      state.buyer = buyer;
      state.card = token;

      fetch("https://3le44rp5o0.execute-api.us-west-2.amazonaws.com/prod/process-payment", {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state)
      })
      .then(async(response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
          }
        setIsLoading(false);
        setIsConfirmation(true);
        })
        .catch(error => {
          setIsLoading(false);
      });       
    }
  };

  const handleZipCodeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length > 5) {
      value = value.substring(0, 5);
    }
    setValue("zipCode", value, { shouldValidate: true });
    updateAppointment('zipCode', value);
  };

  const handleRegisterValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.name;
    let value = event.target.value;
    setValue(name as any, value, { shouldValidate: true });
    updateAppointment(name as any, value);
  }

  const handleLettersOnlyValues = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let name = event.target.name;
    let value = event.target.value.replace(/[^a-zA-Z]/g, "");

    if (value.length > 32) {
      value = value.substring(0, 32);
    }
    setValue(name as any, value, { shouldValidate: true });
    updateAppointment(name as any, value);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.name;
    let value = event.target.value;

    value = formatPhoneNumber(value);
    setValue(name as any, value, { shouldValidate: true });
    updateAppointment(name as any, value);
  };

  const handleBookLaterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("isBookLater", e.target.checked);
    updateAppointment('isBookLater', e.target.checked);
  };

  return (
    <div className="px-2 py-3 pb-10 sm:flex sm:flex-row-reverse">
      <form className="w-full">
        <PromoCode />
        <div className="mb-[0.125rem] inline-block min-h-[1.5rem] ps-[1.25rem] m-5 pb-4">
          <input
            className="relative float-left -ms-[1.5rem] me-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-secondary-500 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-checkbox before:shadow-transparent before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ms-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-[white] checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-black/60 focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-checkbox checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ms-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-[white] checked:focus:after:bg-transparent rtl:float-right dark:border-neutral-400 dark:checked:border-primary dark:checked:bg-primary"
            type="checkbox"
            onChange={handleBookLaterChange}
          />
          <div className="relative group">
            <label
              className="inline-block ps-[0.15rem] hover:cursor-pointer text-base"
              htmlFor="checkboxDefault"
            >
              <span className="font-semibold flex items-center gap-2 mb-2 text-primary">
                Book now. Pay Later.
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                  />
                </svg>
              </span>
            </label>
            <p className="hidden group-hover:block text-sm">
              We offer a 100% full refund for cancellations made before the
              48-hour mark. For cancellations made within 48 hours of the
              scheduled service, up to 75% of the service fee will be charged,
              as our staff rely on their scheduled hours.
            </p>
          </div>
        </div>

        <PaymentForm
          applicationId="sandbox-sq0idb-xTCvNbPip6_zacIL1qEUBA"
          cardTokenizeResponseReceived={(token, buyer) => {
            onSubmitHandler({ token, buyer });
            console.log("token", token);
            console.log("buyer", buyer);
          }}
          createVerificationDetails={() => ({
            amount: "1.00",
            /* collected from the buyer */
            billingContact: {
              addressLines: ["123 Main Street", "Apartment 1"],
              familyName: "Doe",
              givenName: "John",
              countryCode: "GB",
              city: "London",
            },
            currencyCode: "GBP",
            intent: "CHARGE",
          })}
          locationId="L82DSFWD268AQ"
        >
          <CreditCard
            style={{
              input: {
                fontSize: "17px",
                color: "#142445",
              },
              ".input-container": {
                borderRadius: "0",
                borderWidth: '0',
              },
              ".input-container.is-focus": {
                borderColor: "transparent",
              },
              "input::placeholder": {
                color: "#142445",
              },
              ".message-icon.is-error": {
                color: "#841f13",
              },
              ".message-text.is-error": {
                color: "#841f13",
              },
              ".input-container.is-error": {
                borderColor: "#841f13",
              },
            }}
            className="rounded-none font-sans px-4"
            render={(Button: any) => {
              return (
                <div>
                  <h3 className="text-base font-semibold leading-6 text-primary px-4 mt-8">
                    Billing Details
                  </h3>
                  <div className="flex flex-wrap gap-3 w-full p-5 z-100">
                    <div className="grid grid-cols-2 md:gap-x-3">
                      <label className="relative w-full flex flex-col text-primary">
                        <input
                          {...(register("firstName"))}
                          className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2"
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          onChange={(e) => handleLettersOnlyValues(e)}
                        />
                        {errors.firstName && (
                          <span className="text-red-600 text-sm mb-3 mt-1">
                            {errors.firstName.message}
                          </span>
                        )}
                      </label>
                      <label className="relative w-full flex flex-col text-primary mb-2">
                        <input
                          {...register("lastName")}
                          className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2"
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          onChange={(e) => handleLettersOnlyValues(e)}
                        />
                        {errors.lastName && (
                          <span className="text-red-600 text-sm mb-3 mt-1">
                            {errors.lastName.message}
                          </span>
                        )}
                      </label>
                    </div>

                    <label className="relative w-full flex flex-col text-primary mb-2">
                      <input
                        {...register("address")}
                        className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2"
                        type="text"
                        name="address"
                        placeholder="Address"
                        onChange={(e) => handleRegisterValues(e)}
                      />
                      {errors.address && (
                        <span className="text-red-600 text-sm mb-3 mt-1">
                          {errors.address.message}
                        </span>
                      )}
                    </label>

                    <div className="grid md:grid-cols-6 gap-x-4 md:gap-x-3">
                      <label className="relative w-full flex flex-col text-primary col-span-3">
                        <input
                          {...register("city")}
                          className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2"
                          type="text"
                          name="city"
                          placeholder="City"
                          onChange={(e) => handleLettersOnlyValues(e)}
                        />
                        {errors.city && (
                          <span className="text-red-600 text-sm mb-3 mt-1">
                            {errors.city.message}
                          </span>
                        )}
                      </label>
                      <label className="relative w-full flex flex-col text-primary mb-2 mt-2 md:mt-0">
                        <select
                          {...register("state")}
                          onChange={(e) => handleRegisterValues(e as any)}
                          className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2.5 text-primary"
                        >
                          <option value="">Select State</option>
                          <option value="AL">AL</option>
                          <option value="AK">AK</option>
                          <option value="AR">AR</option>
                          <option value="AZ">AZ</option>
                          <option value="CA" selected={true}>
                            CA
                          </option>
                          <option value="CO">CO</option>
                          <option value="CT">CT</option>
                          <option value="DC">DC</option>
                          <option value="DE">DE</option>
                          <option value="FL">FL</option>
                          <option value="GA">GA</option>
                          <option value="HI">HI</option>
                          <option value="IA">IA</option>
                          <option value="ID">ID</option>
                          <option value="IL">IL</option>
                          <option value="IN">IN</option>
                          <option value="KS">KS</option>
                          <option value="KY">KY</option>
                          <option value="LA">LA</option>
                          <option value="MA">MA</option>
                          <option value="MD">MD</option>
                          <option value="ME">ME</option>
                          <option value="MI">MI</option>
                          <option value="MN">MN</option>
                          <option value="MO">MO</option>
                          <option value="MS">MS</option>
                          <option value="MT">MT</option>
                          <option value="NC">NC</option>
                          <option value="NE">NE</option>
                          <option value="NH">NH</option>
                          <option value="NJ">NJ</option>
                          <option value="NM">NM</option>
                          <option value="NV">NV</option>
                          <option value="NY">NY</option>
                          <option value="ND">ND</option>
                          <option value="OH">OH</option>
                          <option value="OK">OK</option>
                          <option value="OR">OR</option>
                          <option value="PA">PA</option>
                          <option value="RI">RI</option>
                          <option value="SC">SC</option>
                          <option value="SD">SD</option>
                          <option value="TN">TN</option>
                          <option value="TX">TX</option>
                          <option value="UT">UT</option>
                          <option value="VT">VT</option>
                          <option value="VA">VA</option>
                          <option value="WA">WA</option>
                          <option value="WI">WI</option>
                          <option value="WV">WV</option>
                          <option value="WY">WY</option>
                        </select>
                        {errors.state && (
                          <span className="text-red-600 text-sm mb-3 mt-1">
                            {errors.state.message}
                          </span>
                        )}
                      </label>

                      <label className="relative w-full flex flex-col text-primary col-span-2 mb-2 mt-2 md:mt-0">
                        <input
                          {...register("zipCode")}
                          className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2"
                          type="text"
                          name="zipCode"
                          placeholder="Zip Code"
                          onChange={(e) => handleZipCodeValues(e)}
                        />
                        {errors.zipCode && (
                          <span className="text-red-600 text-sm mb-3 mt-1">
                            {errors.zipCode.message}
                          </span>
                        )}
                      </label>
                    </div>
                    <label className="relative w-full flex flex-col text-primary mb-2">
                      <input
                        {...register("email")}
                        className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2"
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => handleRegisterValues(e)}
                      />
                      {errors.email && (
                        <span className="text-red-600 text-sm mb-3 mt-1">
                          {errors.email.message}
                        </span>
                      )}
                    </label>

                    <label className="relative w-full flex flex-col text-primary mb-2">
                      <input
                        {...register("phoneNumber")}
                        className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2"
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        onChange={(e) => handlePhoneNumberChange(e)}
                      />
                      {errors.phoneNumber && (
                        <span className="text-red-600 text-sm mb-3 mt-1">
                          {errors.phoneNumber.message}
                        </span>
                      )}
                    </label>
                  </div>
                  <div className="px-6">
                    <p className="text-sm mt-2 text-primary">
                      By clicking "Book Now," your payment details will be
                      securely submitted. You acknowledge and agree to our{" "}
                      <a href="/terms-conditions" className="underline">
                        terms and conditions
                      </a>
                      , including our cancellation and refund policy, which
                      offers a 100% full refund for cancellations made before
                      the 48-hour mark and up to 75% of the service fee for
                      cancellations made within 48 hours of the scheduled
                      service.
                    </p>

                    {isLoading ? (
                      <Loader />
                    ) : (
                      <Button
                        isLoading={isLoading}                        
                        className="!mt-6 !inline-flex !text-[white] !bg-primary-50 !border-0 !py-4 !px-6 !w-full focus:!outline-none hover:!bg-primary !text-sm md:!text-[1.25rem] !font-bold !justify-between !rounded-none"
                      >
                        <span>Book Now</span>
                        <span>⟶</span>
                      </Button>
                    )}

                    <div className="text-center mx-auto">
                      <p className="text-sm mt-2 text-primary font-semibold">
                        Powered with
                      </p>
                      <div className="flex justify-center mt-1">
                        <img
                          className="object-cover object-center rounded w-1/4"
                          alt="home-sweet-home"
                          src={SquareLogo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </PaymentForm>
      </form>
    </div>
  );
};

export default AppointmentPaymentForm;
