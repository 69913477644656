import React from 'react';
import CheckMark from '../../assets/icons/CheckMark';
import { useAppointment } from '../../helper/AppointmentContext';

interface ConfirmationProps {
}

const Confirmation: React.FC<ConfirmationProps> = () => {
    const { state } = useAppointment();
    return (
        <div className='px-2 md:px-10 mb-10 text-center'>
            <div className='mx-auto justify-center flex mb-6 mt-4'>
                <CheckMark />
            </div>
            <h1 className='mb-6 text-2xl'>Your reservation has been submitted!</h1>
            <p className='mb-6 mx-6'>Thank you for choosing PrimeTime Cleaners! We are delighted to confirm your booking
            {(state.appointmentDate && state.appointmentTime) && ` for ${state.appointmentDate} at ${state.appointmentTime}`}
            .</p>
            <p className='mb-20 mx-6'>A confirmation email has been sent to the provided email address with all the details of your booking.</p>
        </div>
    );
};

export default Confirmation;