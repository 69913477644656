import React from "react";
import ContactForm from "../common/form/ContactForm";
import Google from "../assets/img/google.png";
import Yelp from "../assets/img/yelp.png";
import TrustPilot from "../assets/img/trust-pilot.png";

interface HeroFormProps {
  setOpenPaymentModal: (open: boolean) => void;
}

const HeroForm: React.FC<HeroFormProps> = ({setOpenPaymentModal}) => {
  return (
    <section className="text-gray-600 body-font" id="booking">
      <div className="container mx-auto px-6 md:px-10 flex flex-wrap items-center grid grid-cols-1 md:grid-cols-2 md:gap-14">
        <div className="w-full bg-primary-50 flex items-center justify-center h-full p-4 md:p-8">
          <div>
            <h2 className="pb-2 md:pb-4 text-[2rem] md:text-4xl text-[white] leading-tight md:leading-none font-bold">
              Keeping it fresh & Sparkling Clean
            </h2>
            <h4 className="mt-0 md:mt-4 text-2xl md:text-[2.5rem] text-[white] leading-tight md:w-3/4">
              Expertly trained and background-checked, our team is here to serve
              you.
            </h4>
            <p className="text-sm md:text-base mt-4 md:mt-10 text-[white] md:w-3/4">
              Satisfaction Guaranteed! All our work is covered under our 24 hour
              coverage. If we missed a spot, let us know and we will come back
              and finish the job within 24 hours.
            </p>
            <p className="text-sm md:text-base mt-3 md:mt-6 text-[white] md:w-3/4">
              We clean Sunday – Saturday, 8am – 5pm.
            </p>
            <div className="mt-10 md:mt-16">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-10 ">
                <img
                  className="object-cover object-center w-full place-self-center px-20 md:p-4"
                  alt="google"
                  src={Google}
                />
                <img
                  className="object-cover object-center w-full place-self-center px-20 md:p-2"
                  alt="yelp"
                  src={Yelp}
                />
                <img
                  className="object-cover object-center w-full place-self-center px-12 md:px-0"
                  alt="trust-pilot"
                  src={TrustPilot}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full rounded-lg p-0 md:p-8 flex flex-col md:ml-auto w-full mt-14 md:mt-0">
          <h2 className="text-primary text-[2rem] md:text-4xl mb-5 leading-none font-bold">
            Book Now.
            <br /> Pay Later.
          </h2>
          <p className="my-6 md:w-4/6 text-primary">
            We ensure excellence with a fully-insured, expertly trained, and
            highly experienced team.
          </p>
          <div className="">
            <ContactForm setOpenPaymentModal={setOpenPaymentModal}/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroForm;
