import React from 'react';

interface ProgressBarProps {
    isFinalStep: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ isFinalStep }) => {
    return (
        <div className="progress-bar flex items-center">
            <div className="flex h-10 w-10 rounded-full bg-primary-50 flex-shrink-0 relative left-2.5 text-[white] font-medium justify-center items-center">1</div>
            <div className={`flex-grow mx-2 h-2 rounded ${isFinalStep ? 'bg-primary-50' : 'bg-gray-400'} overflow-hidden`}></div>
            <div className={`flex h-10 w-10 rounded-full ${isFinalStep ? 'bg-primary-50' : 'bg-gray-400'} flex-shrink-0 relative right-2.5 text-[white] font-medium justify-center items-center`}>2</div>
        </div>
    );
};

export default ProgressBar;