import React from "react";

class NotFoundPage extends React.PureComponent {
    render() {
        return (
            <section className="bg-white">
                <div className="container md:min-h-screen px-6 py-12 mx-auto lg:flex lg:items-center lg:gap-12">
                    <div className="wf-ull lg:w-1/2">
                        <p className="text-sm font-medium text-primary">
                            404 error
                        </p>
                        <h1 className="mt-3 text-2xl font-semibold text-primary md:text-3xl">
                            Page not found
                        </h1>
                        <p className="mt-4 text-primary">
                            Sorry, the page you are looking for doesn't exist.
                        </p>

                        <div className="flex items-center mt-6 gap-x-3">
                            <a className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border gap-x-2 sm:w-auto hover:bg-primary-50 hover:text-[white]" href="/">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 rtl:rotate-180"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                                    />
                                </svg>

                                <span>Take me home</span>
                            </a>
                        </div>
                    </div>

                    <div className="relative w-full mt-12 lg:w-1/2 lg:mt-0">
                        <img
                            className="w-full max-w-lg lg:mx-auto"
                            src="https://merakiui.com/images/components/illustration.svg"
                            alt="404"
                        />
                    </div>
                </div>
            </section>
        );
    }
}

export default NotFoundPage;
