import React from "react";
import HomeSweetHome from "../assets/img/home-sweet-home.jpeg";
import Telephone from "../assets/icons/Telephone";
import { Link } from "react-scroll";

class Hero extends React.PureComponent {
  render() {
    return (
      <section className="text-primary">
        <div className="container mx-auto flex px-10 py-8 md:py-24 md:flex-row flex-col items-center grid md:grid-cols-2 md:gap-14">
          <div className="lg:flex-grow flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-left">
            <h1 className="title-font text-3xl md:text-5xl mb-5 font-medium text-primary font-display leading-none">
              Home Cleaning Made Easy.
            </h1>
            <h4 className="mb-4 leading-normal text-xl md:text-2xl font-medium md:w-3/4">
              Our team is fully-insured, background check and expertly trained.
            </h4>
            <h3 className="mb-4 md:mb-8 leading-normal text-xl md:text-2xl font-semibold w-full md:w-3/4 text-left">
              <a href="tel:+16195650641" className="flex items-end">
                <Telephone />
                <span className="ml-2 md:ml-4">(619) 565-0641</span>
              </a>
            </h3>
            <div className="flex self-baseline justify-center">
              <Link
                to="services"
                smooth={true}
                duration={500}
                className="inline-flex text-[white] bg-primary-50 border-0 py-4 px-6 focus:outline-none hover:bg-primary text-sm md:text-[1.25rem] font-bold cursor-pointer"
              >
                Book Now. Pay Later
              </Link>
            </div>
          </div>
          <div className="w-full md:ml-4">
            <img
              className="object-cover object-center rounded w-full"
              alt="home-sweet-home"
              src={HomeSweetHome}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
