import React from "react";
import AccordionFAQ from "./AccordionFAQ";

class ChecklistFAQ extends React.PureComponent {
  render() {
    return (
      <section className="text-primary body-font overflow-hidden">
        <div className="container px-10 py-8 md:py-24 mx-auto">
          <div className="flex flex-wrap -m-12">
            <div className="p-12 md:w-1/2 flex flex-col items-start">
              <h2 className="sm:text-3xl text-2xl title-font font-medium text-primary mt-4 mb-4 leading-tight">
                50-Point House Cleaning Checklist
              </h2>
              <p className="leading-relaxed mb-4 md:w-3/4">
                Our 50-Point Checklist is our signature service that ensures
                your entire home will be cleaned, top to bottom.
              </p>
              <p className="leading-relaxed mb-4 md:w-3/4">
                Since each home is different, we customize each service to fit
                your home, needs and preferences.
              </p>
              <p className="leading-relaxed mb-4 md:w-3/4">
                Special requests? No problem. From trouble spots to off-limit
                zones, we’ll create a cleaning plan that matches your home and
                preferences.
              </p>
            </div>
            <div className="p-4 md:p-12 md:w-1/2 flex flex-col items-start bg-primary-50">
              <AccordionFAQ textColor={"white"} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ChecklistFAQ;
