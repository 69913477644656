import React, { useEffect, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { AppointmentFormData, useAppointment } from "../helper/AppointmentContext";

interface TimeDropdownProps {
  register: UseFormRegister<AppointmentFormData>;
  setValue: UseFormSetValue<AppointmentFormData>;
}

const TimeDropdown: React.FC<TimeDropdownProps> = ({ register, setValue }) => {
  const [selectedTime, setSelectedTime] = useState("");
  const { state, updateAppointment } = useAppointment();

  useEffect(() => {
    if (state.appointmentTime) {
      setSelectedTime(state.appointmentTime);
      setValue("appointmentTime", state.appointmentTime);
    }
  }, []);

  const generateOptions = () => {
    const options = [];
    for (let hour = 8; hour <= 16; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const formattedMinute = minute === 0 ? "00" : `${minute}`;
        const amPm = hour < 12 ? "AM" : "PM";
        const time = `${formattedHour}:${formattedMinute} ${amPm}`;
        options.push(
          <option key={time} value={time}>
            {time}
          </option>
        );
      }
    }
    return options;
  };

  const handleChange = (event: any) => {
    setSelectedTime(event.target.value);
    setValue("appointmentTime", event.target.value)
    updateAppointment("appointmentTime", event.target.value)
  };

  return (
    <div className="max-w-3xl mx-auto mb-2 md:py-2 px-4 md:px-8 font-semibold">
      <select
        {...register("appointmentTime")}
        value={selectedTime}
        onChange={handleChange}
        className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2.5 text-primary"
      >
        <option value="">Select Time</option>
        {generateOptions()}
      </select>
    </div>
  );
};

export default TimeDropdown;
