import * as yup from "yup";
import { validateCreditCardNumber } from "./VlicadateCreditCardNumber";

declare module "yup" {
  interface StringSchema {
    validateCreditCard(message: string): StringSchema;
  }
}

yup.addMethod(yup.string, "validateCreditCard", function (message) {
  return this.test("validateCreditCard", message, function (value) {
    const { path, createError } = this;
    if (!value) return true;
    const validation = validateCreditCardNumber(value);
    return (
      validation.valid || createError({ path, message: validation.message })
    );
  });
});

const EmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
});

const ContactSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
  phoneNumber: yup
    .string()
    .required("Please enter your phone number")
    .length(14, "Please enter a valid phone number"),
});
const ContactFormSchema = yup.object().shape({
  ...ContactSchema.fields,
  cleaningService: yup.string().required("Please select service"),
});

const AppointmentSchema = yup.object().shape({
  appointmentDate: yup.string().required("Please select an appointment date"),
  appointmentTime: yup.string().required("Please select an appointment time"),
  selectedServices: yup.array().of(yup.string()),
  message: yup.string(),
});

const CreditCardSchema = yup.object().shape({
  card_number: yup
    .string()
    .required("Please enter a card number")
    .validateCreditCard("Please enter a valid card number")
    .length(19, "Please enter a valid card number"),
  card_cvc: yup.string().required("Please enter a CVC"),
  expiration_date: yup.string().required("Please enter date"),
});

const CompleteForm = yup.object().shape({
  ...ContactSchema.fields,
  ...CreditCardSchema.fields,
  isBookLater: yup.boolean(),
  address: yup.string().required("Please enter your address"),
  state: yup.string().required("Please select a state"),
  city: yup.string().required("Please enter a city"),
  zipCode: yup.string().required("Enter zip code"),
});

const formatPhoneNumber = (value: string): string => {
  value = value.replace(/\D/g, "");
  value = value.substring(0, 10);

  const phoneNumber = value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  return phoneNumber;
};

export {
  AppointmentSchema,
  ContactFormSchema,
  CompleteForm,
  EmailSchema,
  formatPhoneNumber,
};
