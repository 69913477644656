import React from 'react';
import { useForm } from 'react-hook-form';

interface PromoCodeProps {
}

const PromoCode: React.FC<PromoCodeProps> = () => {
    const {
        register,
        formState: { errors },
    } = useForm();

    return (
        <div className='px-4 py-3 grid grid-cols-3 gap-3'>
            <label className="relative w-full flex flex-col text-primary col-span-2">
                <input
                {...register("promo")}
                className="border-b-2 border-gray-400 focus:border-primary outline-none w-full py-2"
                type="text"
                name="promo"
                placeholder="Enter Promo Code"
                //   onChange={handlePhoneNumberChange}
                />
                {errors.promo && (
                    <span className="text-red-600 text-sm mb-3 mt-1">
                        {String(errors.promo.message)}
                    </span>
                )}
            </label>
            <button
                type="button"
                className="text-primary-50 bg-[white] border-2 border-primary-50 px-2 w-full focus:outline-none hover:bg-primary hover:text-[white] text-sm md:text-[1.25rem] font-bold justify-between text-center"
                onClick={() => console.log('submitted')}
            >
                <span>Apply</span>
            </button>
        </div>
    );
};

export default PromoCode;