import React from "react";
import Logo from "../assets/icons/Logo";
import Link from "../helper/Link";

class Menu extends React.PureComponent {
    render() {
        return (
            <header className="text-gray-600 body-font">
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center justify-center">
                    <a className="flex items-center mb-4 md:mb-0" href="/">
                        <Logo />
                        <span className="ml-4 text-2xl font-bold text-primary">
                            Primetime Cleaners
                        </span>
                    </a>
                    <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center mt-8 md:mt-0">
                        <Link
                            to="booking"
                            className="mr-5 md:mr-16 hover:text-primary-50 cursor-pointer font-semibold"
                        >
                            Book Now
                        </Link>
                        <Link
                            to="services"
                            className="mr-5 md:mr-16 hover:text-primary-50 cursor-pointer font-semibold"
                        >
                            Services
                        </Link>
                        <Link
                            to="services"
                            className="inline-flex text-[white] bg-primary-50 border-0 py-4 px-6 focus:outline-none hover:bg-primary text-sm md:text-base font-semibold cursor-pointer"
                        >
                            <span>Get Started</span>
                        </Link>
                    </nav>
                </div>
            </header>
        );
    }
}

export default Menu;
