import React, { useEffect, useState } from 'react';
import AppointmentForm from '../common/form/AppointmentForm';
import { Link } from "react-scroll";
import Modal from '../common/Modal';
import CancellationModal from '../common/Modal/CancellationModal';
import CheckMark from '../assets/icons/CheckMark';

interface UpdateReservationProps {
}

const UpdateReservation: React.FC<UpdateReservationProps> = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

    const onSubmit = (data: any) => {
        setIsLoading(true);
        setIsConfirmed(true);
    };
    
    const onCancel = () => {
        console.log('canceled');
        setOpen(false);
        setIsConfirmed(true);
    }

    useEffect(() => {}, [open]);

    const CancelModal = () => {
        return (
            <Modal open={open} setOpen={setOpen} >
                <CancellationModal setOpen={setOpen} onCancel={onCancel} isLoading={isLoading} />
            </Modal>
        )
    }

    const ReservationDetails = () => (
        <>
            <div className="mx-6 md:mx-0">
                <h1 className='text-2xl md:text-3xl font-medium mb-4 md:mb-6 md:text-center'>Update Your Reservation</h1>
                <p className="text-base md:text-xl md:text-center mb-4 md:mb-8 mt-2">We understand that plans can change, and we're here to help make the process of modifying your reservation as smooth as possible. Please kindly adhere to the instructions provided below to make any necessary updates to your reservation details. If you wish to cancel, please <Link to="cancel" className="underline cursor-pointer" duration={500} smooth={true}>click here.</Link></p>
            </div>
            <div className='px-6 md:px-0'>
                <p className="text-base md:text-xl md:mb-2">Reservation Number: </p>
                <p className="text-base md:text-xl md:mb-2">Appointment Date: </p>
                <p className="text-base md:text-xl">Service Type: </p>
            </div>
            <div className="mt-6 md:mt-20 md:mx-64">
                <AppointmentForm setFinalStep={() => {}} buttonText="Submit" isLoading={isLoading} onSubmit={onSubmit} />
                {!isLoading && <div className="mx-12" id="cancel">
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className="mt-2 inline-flex text-[white] bg-red-600 border-0 py-4 px-6 w-full focus:outline-none hover:bg-red-700 text-sm md:text-[1.25rem] font-bold justify-between"
                    >
                        <span>Cancel Appointment</span>
                        <span>⟶</span>
                    </button>
                </div>}
            </div>
        </>
    );

    const Confirmation = () => (
        <div className="mx-6 md:mx-0 md:my-40">
            <div className='mx-auto justify-center flex mb-6 mt-4'>
                <CheckMark />
            </div>
            <h1 className='text-2xl md:text-3xl font-medium mb-4 md:mb-6 md:text-center'>Appointment Updated</h1>
            <p className="text-base md:text-xl md:text-center mb-4 md:mb-8 mt-2">Thank you for updating your appointment. A confirmation email with the updated details will be sent to you shortly. If you have any further questions or require additional assistance, please do not hesitate to contact us.</p>
        </div>
    );
        

    return (
        <div className="mx-auto md:px-72 mt-16 md:mt-28 text-primary">
            {isConfirmed && <Confirmation />}
            {!isConfirmed && <ReservationDetails />}
            <CancelModal />
        </div>
    );
};

export default UpdateReservation;