import Logo from "../../assets/icons/Logo";
import Loader from "../Loader";

interface CancellationModalProps {
    setOpen: (value: boolean) => void;
    onCancel: () => void;
    isLoading?: boolean;
}

const CancellationModal: React.FC<CancellationModalProps> = ({ setOpen, onCancel, isLoading }) => {
    const CancelButton = () => (
        <button
            onClick={() => onCancel()}
            type="button"
            className="mt-2 inline-flex text-[white] bg-red-600 border-0 py-4 px-6 w-full focus:outline-none hover:bg-red-700 text-sm md:text-[1.25rem] font-bold justify-between"
        >
            <span>Cancel</span>
            <span>⟶</span>
        </button>
    );

    return (
        <div>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 flex items-center mb-4 md:mb-0">
                        <Logo />
                        <span className="ml-4 text-xl font-bold text-primary">
                        Primetime Cleaners
                        </span>
                    </h3>
                    
                    <div>
                        <div className="mt-10 mb-6">
                            <h4 className="font-medium text-xl text-primary mb-4">To proceed with cancellation, please confirm by clicking the button below.</h4>
                            <p className="group-hover:block text-sm">
                                We offer a 100% full refund for cancellations made before the
                                48-hour mark. For cancellations made within 48 hours of the
                                scheduled service, up to 75% of the service fee will be charged,
                                as our staff rely on their scheduled hours.
                            </p>
                        </div>
                        {isLoading ?
                            <Loader className="text-[white] bg-red-600 hover:bg-red-700" /> :
                            <CancelButton />
                        }
                        <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="mt-2 inline-flex text-primary bg-[white] border-2 border-primary py-4 px-6 w-full focus:outline-none hover:bg-primary-50 hover:text-[white] text-sm md:text-[1.25rem] font-bold justify-between mt-4 md:mt-8 mb-4 md:mb-8"
                        >
                            <span>Go Back</span>
                            <span>⟶</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CancellationModal;
