import React from "react";

class BudgetInfo extends React.PureComponent {
  render() {
    return (
      <section className="text-primary">
        <div className="container px-10 py-8 md:pt-24 mx-auto">
          <div className="text-center mb-8 md:mb-14">
            <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-primary mb-4">
              Your Budget
            </h1>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              Our budget-friendly plans mean you never pay for what you don’t’
              need. So, whatever your cleaning needs or budget, we’ll work with
              you to make sure you get exactly the kind of results you want.
            </p>
          </div>
          <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
            <div className="p-2 sm:w-1/2 w-full">
              <div className="p-4">
                <div>
                  <div className="rounded flex py-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      className="text-blue-500 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <h4 className="title-font font-medium">
                      Fast Over-the-Phone Estimate
                    </h4>
                  </div>
                  <p>
                    Give us a call, let us know what you need, and we’ll give
                    you an estimate right then and there. No waiting around for
                    us to visit your home.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-2 sm:w-1/2 w-full">
              <div className="p-4">
                <div>
                  <div className="rounded flex py-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      className="text-blue-500 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <h4 className="title-font font-medium">
                      No Hassle Payment
                    </h4>
                  </div>
                  <div>
                    <p>
                      We accept Mastercard, Visa, and American Express, making billing easy and simple. You are charged on the day of your service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2 sm:w-1/2 w-full">
              <div className="p-4">
                <div>
                  <div className="rounded flex py-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      className="text-blue-500 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span className="title-font font-medium">
                      Don’t Pay for What You Don’t Need
                    </span>
                  </div>
                  <p>
                    If you don’t need us to clean all the rooms in your home,
                    just choose your Priority Clean — where we focus on the
                    areas of your that you select.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-2 sm:w-1/2 w-full">
              <div className="p-4">
                <div>
                  <div className="rounded flex py-4 h-full items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      className="text-blue-500 w-6 h-6 flex-shrink-0 mr-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <h4 className="title-font font-medium">
                      Never Feel Tied Down
                    </h4>
                  </div>
                  <div>
                    <p>
                      Should you need to cancel service or skip a cleaning day,
                      just us 48 hours’ notice. We will need to charge up to 75%
                      of your cleaning for any cancel under 48 hours because our
                      staff depend on their scheduled hours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BudgetInfo;
