import { useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

interface MyLinkProps {
  to: string;
  scroll?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Link = ({ to, children, className }: MyLinkProps) => {
  const location = useLocation();
  const currentPage = location.pathname;

  if (currentPage === "/") {
    return (
      <ScrollLink to={to} duration={500} smooth={true} className={className}>
        {children}
      </ScrollLink>
    );
  } else {
    return (
      <a href={`/#${to}`} className={className}>
        {children}
      </a>
    );
  }
};
export default Link;
