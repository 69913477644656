import "./styles/tailwind.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppointmentProvider } from "./helper/AppointmentContext";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Footer from "./common/Footer";
import Menu from "./common/Menu";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import UpdateReservation from "./pages/UpdateReservation";

// api check valid CC
// api submit payment -> check available dates (square?)
// api submit payment -> save to DB -> campaign klaviyo
// api email -> subscription -> campaign klaviyo
// api to cancel/ change reservation
// promo code

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <AppointmentProvider>
        <Menu />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/update/:paymentId/reservation/:reservationId" element={<UpdateReservation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </AppointmentProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
