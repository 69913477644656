import React from "react";

class Location extends React.PureComponent {
  render() {
    return (
      <section className="text-gray-600 body-font relative h-[30rem]">
        <div className="absolute inset-0 bg-gray-300">
          <iframe
            width="100%"
            height="100%"
            title="map"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=San+Diego+Christy's Donuts&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
            style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
          ></iframe>
        </div>
      </section>
    );
  }
}

export default Location;
