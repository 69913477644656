import React from "react";
import Clock from "../assets/icons/Clock";
import { useAppointment } from "../helper/AppointmentContext";

interface ServicesProps {
  setOpenPaymentModal?: (open: boolean) => void;
}

const Services: React.FC<ServicesProps> = ({setOpenPaymentModal}) => {
  const { state, updateAppointment } = useAppointment();
  
  const selectService = (e: React.MouseEvent<HTMLButtonElement>) => {  
    if (setOpenPaymentModal) {
      updateAppointment('cleaningService', (e.target as HTMLButtonElement)?.value);
      setOpenPaymentModal(true);
    }
  };

  return (
    <section className="text-primary body-font overflow-hidden" id="services">
      <div className="container px-4 md:px-10 py-16 md:py-20 mx-auto">
        <div className="flex flex-col text-center w-full mb-10 md:mb-20">
          <h1 className="sm:text-4xl text-[2.5rem] md:text-3xl font-medium mb-2 text-primary leading-none md:leading-tight">
            Primetime Cleaner Services
          </h1>
        </div>
        <div className="grid md:grid-cols-3 gap-10 m-4">
          <div className="w-full">
            <div className="h-full p-4 md:p-10 bg-gray-300 flex flex-col relative overflow-hidden">
              {state?.services && <h2 className="text-[2.5rem] md:text-3xl text-primary font-bold mb-6 leading-none h-[5.25rem]">
                {state.services.standardCleaning.name}
              </h2>}
              <div className="">
                <p>Starting at</p>
                {state?.services && <h2 className="text-[2rem] md:text-[2.5rem] text-primary font-bold mt-2 mb-6 leading-none">
                  ${(state.services.standardCleaning.price.toString()).split('.')[0]} 
                </h2>}
                <button value="standardCleaning" className="mx-auto text-center text-primary-50 bg-gray-4300 border border-primary py-4 px-6 w-full focus:outline-none w-full font-medium mb-4 hover:bg-primary hover:text-[white]" onClick={selectService}>
                  Get Started
                </button>
                <p className="flex place-items-center text-xs text-gray-500 my-3">
                  <Clock />
                  <span className="ml-2">3 hours max for this pricing</span>
                </p>
                <p className="text-xs text-gray-500 my-3">
                  This is our most popular cleaning! With advanced techniques and processes, we will make your space look good!
                </p>
                <p className="text-xs text-gray-500 my-3">
                  About 90% of our clients receive our standard clean every two weeks. We clean your home so you can relax and do the things you love.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="h-full p-4 md:p-10 bg-gray-300 flex flex-col relative overflow-hidden">
                <span className="text-primary-50 px-3 py-1 tracking-widest text-xs absolute right-0 top-1 font-bold">
                  POPULAR
                </span>
                {state?.services && <h2 className="text-[2.5rem] md:text-3xl text-primary font-bold mb-6 leading-none h-[5.25rem]">
                  {state.services.deepCleaning.name}
                </h2>}
                <div className="">
                  <p>Starting at</p>
                  {state?.services && <h2 className="text-[2.5rem] text-primary font-bold mt-2 mb-6 leading-none">
                    ${(state.services.deepCleaning.price.toString()).split('.')[0]}
                  </h2>}
                  <button value="deepCleaning" className="mx-auto text-center text-[white] bg-primary-50 border border-primary-50 py-4 px-6 w-full focus:outline-none w-full font-medium mb-4 hover:bg-primary" onClick={selectService}>
                    Get Started
                  </button>
                  <p className="flex place-items-center text-xs text-gray-500 my-3">
                    <Clock />
                    <span className="ml-2">5 hours max for this pricing</span>
                  </p>
                  <p className="text-xs text-gray-500 my-3">
                    This type of cleaning goes beyond a regular surface clean often requires more effort, time, and attention to detail. This service is recommended on the 1st visit and is our highest rated experience. Our team is trained and ready.
                  </p>
                </div>
              </div>
            </div>
          <div className="w-full">
            <div className="h-full p-4 md:p-10 bg-gray-300 flex flex-col relative overflow-hidden">
                {state?.services && <h2 className="text-[2.5rem] md:text-3xl text-primary font-bold mb-6 leading-none h-[5.25rem]">
                {state.services.moveOutInCleaning.name}
                </h2>}
                <div className="">
                  <p>Starting at</p>
                  {state?.services && <h2 className="text-[2rem] md:text-[2.5rem] text-primary font-bold mt-2 mb-6 leading-none">
                    ${(state.services.moveOutInCleaning.price.toString()).split('.')[0]}
                  </h2>}
                  <button value="moveOutInCleaning" className="mx-auto text-center text-primary-50 bg-gray-300 border border-primary py-4 px-6 w-full focus:outline-none w-full font-medium mb-4 hover:bg-primary hover:text-[white]" onClick={selectService}>
                    Get Started
                  </button>
                  <p className="flex place-items-center text-xs text-gray-500 my-3">
                    <Clock />
                    <span className="ml-2">5 hours max for this pricing</span>
                  </p>
                  <p className="text-xs text-gray-500 my-3">
                    Say goodbye to your old place with ease! Let our professional move out cleaning service handle the dirt and game. Our team will deep clean every nook and cranny, leaving your space ready for the next residents.
                  </p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
