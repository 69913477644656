import React from "react";

class Clock extends React.PureComponent {
  render() {
    return (
      <svg
        className="h-8 w-8 text-primary"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {" "}
        <path stroke="none" d="M0 0h24v24H0z" />{" "}
        <circle cx="12" cy="12" r="9" /> <polyline points="12 7 12 12 15 15" />
      </svg>
    );
  }
}

export default Clock;
