import React, { useEffect } from "react";
import Icon from "../helper/DynamicIcon";
import { useAppointment } from "../helper/AppointmentContext";

interface AdditionalItemProps {
  register: any;
  service: {
    name: string;
    details: { name: string; price: number; count?: number };
  };
  isActive: boolean;
  hasCount: boolean;
  handleCheckboxChange: (serviceName: string) => void;
}

const AdditionalItem: React.FC<AdditionalItemProps> = ({
  register,
  service,
  isActive,
  hasCount,
  handleCheckboxChange,
}) => {
  const { state, updateAppointment } = useAppointment();
  useEffect(() => {updateTotal()}, [state.total, state.selectedServices, state.additionalServices]);

  const handleIncrement = (serviceName: string) => {
    const updatedServices = state.additionalServices.map((service: any) => {
      if (
        service.name === serviceName &&
        service.details.hasOwnProperty("count")
      ) {
        service.details.count += 1;
      }
      return service;
    });
    updateAppointment("additionalServices", updatedServices);
  };

  const handleDecrement = (serviceName: string) => {
    const updatedServices = state.additionalServices.map((service: any) => {
      if (
        service.name === serviceName &&
        service.details.hasOwnProperty("count")
      ) {
        service.details.count -= 1;
      }
      return service;
    });
    updateAppointment("additionalServices", updatedServices);
  };

  const updateTotal = () => {
    let total = parseFloat(state.total) || 0;

    if (state.selectedServices && state.additionalServices) {
      state.selectedServices.forEach((service: string) => {
        const selectedService = state.additionalServices.find((additionalService) => additionalService.name === service);
        if (selectedService) {
          if (selectedService.details.hasOwnProperty('count') && selectedService.details.count) {
            total += (selectedService.details.price * selectedService.details.count);
          } else {
            total += selectedService.details.price;
          }
        }
      });
    }

    updateAppointment('total', total);
  }

  return (
    <label key={service.name} className="relative">
      <input
        type="checkbox"
        {...register(service.name)}
        checked={isActive}
        className="hidden"
      />
      <div
        className={`relative flex flex-col items-center justify-center w-32 md:w-[120px] h-24 md:h-20 border border-gray-300 rounded cursor-pointer z-0 ${
          isActive
            ? "bg-primary text-[white]"
            : "hover:bg-primary-50 hover:text-[white]"
        }`}
        onClick={() => !isActive && handleCheckboxChange(service.name)}
      >
        {((isActive && hasCount) && (service.details.count as any) > 1) && (
          <button
            className="absolute top-0 left-0 hover:bg-red-600 text-[white] h-full bg-primary-50 rounded-l z-10"
            onClick={() => handleDecrement(service.name)}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6 relative left-0"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 12h14"
              />
            </svg>
          </button>
        )}

        <div
          className={`absolute w-16 h-16 rounded-full flex items-center justify-center`}
          onClick={() => handleCheckboxChange(service.name)}
        ></div>

        {isActive && hasCount && (
          <>
            <div className="absolute w-full h-full bg-[black] z-0 opacity-70 rounded"  onClick={() => handleCheckboxChange(service.name)} />
            <div className="absolute">
              <p className="relative left-0 top-0 text-center text-2xl">
                {service.details.count}
              </p>
            </div>
          </>
        )}

        <Icon name={service.name} />
        <span className="mt-3 md:mt-1">${service.details.price}</span>

        {isActive && hasCount && (
          <button
            className="absolute top-0 right-0 hover:bg-red-600 text-[white] h-full bg-primary-50 rounded-r"
            onClick={() => handleIncrement(service.name)}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2.5"
              stroke="currentColor"
              className="size-6 relative right-0"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>
        )}
      </div>
      <span className="mt-1 absolute Left-[3px] w-[8rem] text-center text-sm">
        {service.details.name}
      </span>
    </label>
  );
};

export default AdditionalItem;
