import { PureComponent } from "react";

class TermsConditions extends PureComponent {
  render() {
    return (
      <div className="max-w-[40rem] mx-auto px-6 md:px-0">
        <h2 className="text-3xl font-bold text-center mb-10">
          Terms &amp; Conditions
        </h2>

        <div>
          <strong>1. Introduction</strong>
          <p className="ml-4 my-2">
            Welcome to Apex Cleaners. These Terms and Conditions (“Terms”)
            govern your use of our cleaning services. By booking our services,
            you agree to comply with and be bound by these Terms. If you do not
            agree with these Terms, please do not use our services.
          </p>

          <strong>2. Services</strong>
          <p className="ml-4 my-2">
            We provide professional home cleaning services, which include but
            are not limited to the tasks listed in our 50-Point Cleaning
            Checklist. The scope of services and specific tasks to be performed
            will be detailed in the service agreement provided at the time of
            booking.
          </p>

          <strong>3. Booking and Payment</strong>
          <p className="ml-4 my-2">
            Booking: Services must be booked in advance through our website,
            phone, or email. Availability is subject to confirmation.Payment:
            Payment for services is due upon completion unless otherwise agreed.
            We accept Visa, Mastercard, and American Express.
          </p>

          <p className="ml-4 my-2">
            Cancellations and Rescheduling: Cancellations or rescheduling must
            be made at least 48 hours in advance. Failure to provide sufficient
            notice may result in a cancellation fee of 75% of the total amount.
          </p>

          <strong>4. Customer Responsibilities</strong>
          <p className="ml-4 my-2">
            Access: Customers must provide access to the property at the
            scheduled time. If access is not provided and the cleaning team is
            unable to perform the services, a fee may apply.Safety: Customers
            are responsible for ensuring a safe working environment for our
            cleaning staff, including securing pets and disclosing any potential
            hazards.
          </p>

          <strong>5. Satisfaction Guarantee</strong>
          <p className="ml-4 my-2">
            We strive to provide the highest quality cleaning services. If you
            are not satisfied with any aspect of our service, please contact us
            within 24 hours, and we will address the issue promptly.
          </p>

          <strong>6. Liability</strong>
          <p className="ml-4 my-2">
            Damage and Loss: We take great care in providing our services, but
            we are not liable for any damage or loss unless it is due to our
            negligence. Any claims must be reported within 24 hours of the
            service.Limitations: We are not responsible for damage due to the
            condition of the item or normal wear and tear.Insurance: We carry
            liability insurance to cover any damages caused by our staff during
            service provision.
          </p>

          <strong>7. Right to Cancel</strong>
          <p className="ml-4 my-2">
            Under California law, you have the right to cancel this agreement
            within 3 business days after the date of this agreement without any
            penalty or obligation. To cancel, you must inform us in writing at
            the contact information provided below.
          </p>

          <strong>8. Privacy</strong>
          <p className="ml-4 my-2">
            Your privacy is important to us. We comply with the California
            Consumer Privacy Act (CCPA). Please refer to our Privacy Policy for
            details on how we collect, use, and protect your personal
            information.
          </p>

          <strong>9. Amendments</strong>
          <p className="ml-4 my-2">
            We reserve the right to amend these Terms at any time. Changes will
            be effective upon posting to our website. Continued use of our
            services constitutes acceptance of the revised Terms.
          </p>

          <strong>10. Governing Law</strong>
          <p className="ml-4 my-2">
            These Terms are governed by the laws of the State of California. Any
            disputes arising from these Terms or our services will be resolved
            in the courts of California.
          </p>

          <strong>11. Contact Information</strong>
          <div className="leading-loose mt-4 ml-4">
            <p>
              <strong>Phone:</strong>{" "}
              <a href="tel:+16195650641" className="text-primary-50">
                (619) 565-0641
              </a>
            </p>
            <p>
              <strong>Email:</strong>{" "}
              <a
                href="mailto:info@apexcleaners.space"
                className="text-primary-50"
              >
                info@apexcleaners.space
              </a>
            </p>
            <p>
              <strong>Location:</strong> San Diego, CA
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsConditions;
