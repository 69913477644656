/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import ChevronLeft from "../assets/icons/ChevronLeft";
import ChevronRight from "../assets/icons/ChevronRight";
import { AppointmentFormData, useAppointment } from "../helper/AppointmentContext";

interface CalendarProps {
    register: UseFormRegister<AppointmentFormData>;
    setValue:  UseFormSetValue<AppointmentFormData>;
}

const Calendar: React.FC<CalendarProps> = ({ register, setValue }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [disablePrev, setDisablePrev] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const { state, updateAppointment } = useAppointment();

    useEffect(() => {
        isPrevMonthDisabled();
        isNextMonthDisabled();
        if (state.appointmentDate) {
            const date = parseDateString(state.appointmentDate);
            setSelectedDate(date);
            setValue("appointmentDate", state.appointmentDate);
        }
    }, [currentMonth]);

    const parseDateString = (dateString: string) => {
        const [month, day, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date string');
        }
        return date;
    }

    const daysInMonth = (month:any, year: any) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const getMonthDays = (month:any, year: any) => {
        const totalDays = daysInMonth(month, year);
        const days = [];
        for (let i = 1; i <= totalDays; i++) {
        days.push(i);
        }
        return days;
    };

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const prevMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
    };

    const nextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
    };

    const handleDateClick = (date: any) => {
        const selectedDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), date)
        setSelectedDate(selectedDate);

        const formatDate = selectedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        })
        setValue("appointmentDate", formatDate)
        updateAppointment("appointmentDate", formatDate);
    };

    const isPrevMonthDisabled = () => {
        const currentDate = new Date();
        const isDisabled = currentMonth.getMonth() === currentDate.getMonth() && currentMonth.getFullYear() === currentDate.getFullYear();
        setDisablePrev(isDisabled);
    };

    const isNextMonthDisabled = () => {
        const currentDate = new Date();
        const oneYearFromNow = new Date(currentDate);
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        const isDisabled = currentMonth.getMonth() === oneYearFromNow.getMonth() && currentMonth.getFullYear() === oneYearFromNow.getFullYear();
        setDisableNext(isDisabled);
    };    
    
    return (
        <div className="max-w-3xl mx-auto py-4 px-4 md:px-10">
            <div className="flex items-center justify-between mb-10 ml-2">
                <h1 className="text-xl font-semibold">{monthNames[currentMonth.getMonth()]} {currentMonth.getFullYear()}</h1>
                <div className="flex w-3/12 justify-between">
                    <button onClick={prevMonth} disabled={disablePrev} className={`${disablePrev ? 'text-gray-400' : 'text-primary'}`}><ChevronLeft /></button>
                    <button onClick={nextMonth} disabled={disableNext} className={`${disableNext ? 'text-gray-400' : 'text-primary'}`}><ChevronRight /></button>
                </div>
            </div>
            <div className="grid grid-cols-7 gap-2 justify-items-center">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                    <div key={day} className="text-center text-primary font-semibold">{day}</div>
                ))}
                {Array.from({ length: new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay() }).map((_, index) => (
                    <div key={`empty-${index}`} className="text-center"></div>
                ))}
                {getMonthDays(currentMonth.getMonth(), currentMonth.getFullYear()).map(day => (
                    <div
                        key={day}
                        onClick={() => handleDateClick(day)}
                        {...register("appointmentDate")}
                        className={`text-center w-[2.4375rem] py-[0.4375rem] px-1 rounded-full rounded cursor-pointer ${
                        selectedDate && selectedDate.getDate() === day && selectedDate.getMonth() === currentMonth.getMonth() ? 'bg-primary-50 text-[white]' : 'hover:bg-primary-50 hover:text-[white]'
                        }`}
                    >
                        {day}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Calendar;
