function validateCreditCardNumber(cardNumber: string) {
   cardNumber = cardNumber.replace(/[\s-]/g, '');
   const cardType = getCardType(cardNumber);

   if (!cardType) {
       return { valid: false, message: 'Please enter a valid card number' };
   }

   if (!luhnCheck(cardNumber)) {
       return { valid: false, message: 'Please enter a valid card number' };
   }

   return { valid: true, message: 'Valid card number' };
}

function getCardType(cardNumber: string) {
   const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
   const masterCardRegex = /^(?:5[1-5][0-9]{14}|2(?:2[2-9][0-9]{12}|[3-6][0-9]{13}|7(?:[01][0-9]{12}|20[0-9]{12})))$/;
   const amexRegex = /^3[47][0-9]{13}$/;

   if (visaRegex.test(cardNumber)) {
       return 'Visa';
   } else if (masterCardRegex.test(cardNumber)) {
       return 'MasterCard';
   } else if (amexRegex.test(cardNumber)) {
       return 'American Express';
   }

   return null;
}

function luhnCheck(cardNumber: string) {
   let sum = 0;
   let shouldDouble = false;

   for (let i = cardNumber.length - 1; i >= 0; i--) {
       let digit = parseInt(cardNumber.charAt(i), 10);

       if (shouldDouble) {
           digit *= 2;
           if (digit > 9) {
               digit -= 9;
           }
       }

       sum += digit;
       shouldDouble = !shouldDouble;
   }

   return sum % 10 === 0;
}

export { validateCreditCardNumber };