import { useForm } from "react-hook-form";
import { useAppointment, AppointmentFormData } from "../../helper/AppointmentContext";
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactFormSchema, formatPhoneNumber } from "../../helper/YupResolvers";

interface ContactFormProps {
  setOpenPaymentModal: (open: boolean) => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ setOpenPaymentModal }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ContactFormSchema)
  });
  const { state, updateAppointment } = useAppointment();

  const onSubmit = (data: any) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        updateAppointment(key as keyof AppointmentFormData, data[key as keyof AppointmentFormData]);
      }
    }
    setOpenPaymentModal(true);    
  };

  const handleLettersOnlyValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.name;
    let value = event.target.value.replace(/[^a-zA-Z]/g, ''); 

    if (value.length > 32) {
      value = value.substring(0, 32);
    }
    setValue(name as any, value, { shouldValidate: true });
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let value = event.target.value;

    value = formatPhoneNumber(value);
    setValue(name as any, value, { shouldValidate: true });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-2 md:space-y-6 w-full"
    >
      <div>
        <label className="block mb-2 text-primary">First Name</label>
        <input
          className="border-b-2 border-gray-300 focus:border-primary outline-none w-full py-2"
          {...register("firstName")}
          onChange={handleLettersOnlyValues}
        />
        {errors.firstName && (
          <span className="text-red-600 text-sm">{errors.firstName.message}</span>
        )}
      </div>

      <div>
        <label className="block mb-2 text-primary">Last Name</label>
        <input
          className="border-b-2 border-gray-300 focus:border-primary outline-none w-full py-2"
          {...register("lastName")}
          onChange={handleLettersOnlyValues}
        />
        {errors.lastName && (
          <span className="text-red-600 text-sm">{errors.lastName.message}</span>
        )}
      </div>

      <div>
        <label className="block mb-2 text-primary">Email</label>
        <input
          type="email"
          className="border-b-2 border-gray-300 focus:border-primary outline-none w-full py-2"
          {...register("email", {
            required: "Email is required",
            pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" },
          })}
        />
        {errors.email && (
          <span className="text-red-600 text-sm">{errors.email.message}</span>
        )}
      </div>

      <div>
        <label className="block mb-2 text-primary">Phone Number</label>
        <input
          type="tel"
          className="border-b-2 border-gray-300 focus:border-primary outline-none w-full py-2"
          {...register("phoneNumber")}
          onChange={handlePhoneNumberChange}
        />
        {errors.phoneNumber && (
          <span className="text-red-600 text-sm">{errors.phoneNumber.message}</span>
        )}
      </div>

      <div>
      <select
          className="mt-6 border-b-2 border-gray-300 focus:border-primary outline-none w-full py-2"
          {...register("cleaningService")}
          onChange={(e) => updateAppointment('cleaningService', e.target.value)}
        >
          <option value="">Select Service</option>
          {Object.entries(state.services).map(([key, service]) => (
            <option key={key} value={key}>{service.name} - ${service.price}.00</option>
          ))}
        </select>
        {errors.cleaningService && (
          <span className="text-red-600 text-sm">{errors.cleaningService.message}</span>
        )}
      </div>

      <div>
        <button
          type="submit"
          className="mt-6 inline-flex text-[white] bg-primary-50 border-0 py-4 px-6 w-full focus:outline-none hover:bg-primary text-sm md:text-[1.25rem] font-bold justify-between"
        >
          <span>Book Now</span> 
          <span>⟶</span>
        </button>
      </div>
    </form>
  );
}

export default ContactForm;