import React from "react";
import ChevronDown from "../assets/icons/ChevronDown";

interface AccordionFAQProps {
  textColor?: string;
}

const AccordionFAQ: React.FC<AccordionFAQProps> = ({
  textColor = "primary",
}) => {
  return (
    <div className="p-2 pb-4 md:p-0 space-y-8 md:space-y-2">
      <div
        className="group flex flex-col gap-2 rounded-lg bg-black py-2"
        tabIndex={1}
      >
        <div
          className={`flex cursor-pointer items-center justify-between text-[${textColor}]`}
        >
          <p className="font-medium text-[1.1rem]">Living Room / Family Room</p>
          <ChevronDown />
        </div>
        <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
          <ul
            className={`list-square ml-4 md:ml-5 leading-loose md:leading-relaxed text-sm md:text-base text-[${textColor}]`}
          >
            <li>
              <b>Dust Surfaces:</b> Thoroughly dust all furniture, shelves, and
              picture frames.
            </li>
            <li>
              <b>Vacuum Carpets and Rugs:</b> Ensure all carpets and rugs are
              vacuumed to remove dirt and debris.
            </li>
            <li>
              <b>Mop Hard Floors:</b> Mop hard flooring to achieve a spotless
              finish.
            </li>
            <li>
              <b>Sanitize Touchpoints:</b> Clean and disinfect light switches
              and door handles.
            </li>
            <li>
              <b>Clean Electronics:</b> Wipe down TVs, remote controls, and
              other electronic devices.
            </li>
            <li>
              <b>Baseboard Care:</b> Dust and clean all baseboards.
            </li>
            <li>
              <b>Polish Mirrors and Glass:</b> Ensure all mirrors and glass
              surfaces are streak-free.
            </li>
            <li>
              <b>Furniture Care:</b> Clean under and behind all furniture.
            </li>
            <li>
              <b>Cobweb Removal:</b> Remove cobwebs from ceilings and corners.
            </li>
            <li>
              <b>Wastebasket Sanitation:</b> Empty and sanitize all
              wastebaskets.
            </li>
          </ul>
        </div>
      </div>

      <div
        className="group flex flex-col gap-2 rounded-lg bg-black py-2"
        tabIndex={2}
      >
        <div
          className={`flex cursor-pointer items-center justify-between text-[${textColor}]`}
        >
          <p className="font-medium text-[1.1rem]">Kitchen</p>
          <ChevronDown />
        </div>
        <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
          <ul
            className={`list-square ml-4 md:ml-5 leading-loose md:leading-relaxed text-sm md:text-base text-[${textColor}]`}
          >
            <li>
              <b>Countertop Sanitation:</b> Clean and disinfect all countertops.
            </li>
            <li>
              <b>Sink and Faucet Cleaning:</b> Sanitize sinks and faucets.
            </li>
            <li>
              <b>Cabinet Exteriors:</b> Wipe down the exterior surfaces of
              cabinets and drawers.
            </li>
            <li>
              <b>Appliance Polishing:</b> Clean and polish all major appliances,
              including the refrigerator, oven, and microwave.
            </li>
            <li>
              <b>Refrigerator Exterior:</b> Thoroughly clean the exterior of the
              refrigerator.
            </li>
            <li>
              <b>Microwave Cleaning:</b> Clean both the interior and exterior of
              the microwave.
            </li>
            <li>
              <b>Stovetop and Oven Cleaning:</b> Ensure the stovetop and oven
              are free of grime and food residue.
            </li>
            <li>
              <b>Dishwasher Care:</b> Wipe down the dishwasher door.
            </li>
            <li>
              <b>Backsplash Cleaning:</b> Clean and sanitize the backsplash.
            </li>
            <li>
              <b>Floor Care:</b> Sweep and mop the kitchen floor.
            </li>
            <li>
              <b>Wastebasket Sanitation:</b> Empty and sanitize the kitchen
              wastebasket.
            </li>
          </ul>
        </div>
      </div>

      <div
        className="group flex flex-col gap-2 rounded-lg bg-black py-2"
        tabIndex={3}
      >
        <div
          className={`flex cursor-pointer items-center justify-between text-[${textColor}]`}
        >
          <p className="font-medium text-[1.1rem]">Bathrooms</p>
          <ChevronDown />
        </div>
        <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
          <ul
            className={`list-square ml-4 md:ml-5 leading-loose md:leading-relaxed text-sm md:text-base text-[${textColor}]`}
          >
            <li>
              <b>Sink and Countertop Sanitation:</b> Clean and disinfect sinks
              and countertops.
            </li>
            <li>
              <b>Toilet Cleaning:</b> Scrub and disinfect toilets.
            </li>
            <li>
              <b>Shower and Tub Care:</b> Clean and disinfect showers and tubs.
            </li>
            <li>
              <b>Mirror and Fixture Polishing:</b> Polish all mirrors and chrome
              fixtures.
            </li>
            <li>
              <b>Cabinet Fronts:</b> Wipe down the fronts of cabinets.
            </li>
            <li>
              <b>Accessory Cleaning:</b> Clean soap dishes, toothbrush holders,
              and other bathroom accessories.
            </li>
            <li>
              <b>Light Fixtures and Vents:</b> Dust light fixtures and vents.
            </li>
            <li>
              <b>Floor Care:</b> Mop the bathroom floors.
            </li>
            <li>
              <b>Wastebasket Sanitation:</b> Empty and sanitize bathroom
              wastebaskets.
            </li>
          </ul>
        </div>
      </div>

      <div
        className="group flex flex-col gap-2 rounded-lg bg-black py-2"
        tabIndex={4}
      >
        <div
          className={`flex cursor-pointer items-center justify-between text-[${textColor}]`}
        >
          <p className="font-medium text-[1.1rem]">Bedrooms</p>
          <ChevronDown />
        </div>
        <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
          <ul
            className={`list-square ml-4 md:ml-5 leading-loose md:leading-relaxed text-sm md:text-base text-[${textColor}]`}
          >
            <li>
              <b>Dust Surfaces:</b> Dust all surfaces, including nightstands and
              dressers.
            </li>
            <li>
              <b>Bed Linens:</b> Change bed linens and make beds.
            </li>
            <li>
              <b>Vacuum Carpets and Rugs:</b> Ensure all carpets and rugs are
              vacuumed.
            </li>
            <li>
              <b>Mop Hard Floors:</b> Mop hard flooring for a spotless finish.
            </li>
            <li>
              <b>Mirror and Glass Cleaning:</b> Clean mirrors and glass
              surfaces.
            </li>
            <li>
              <b>Baseboard Care:</b> Dust and clean baseboards.
            </li>
            <li>
              <b>Under-Bed Cleaning:</b> Clean under the bed.
            </li>
            <li>
              <b>Wastebasket Sanitation:</b> Empty and sanitize bedroom
              wastebaskets.
            </li>
          </ul>
        </div>
      </div>

      <div
        className="group flex flex-col gap-2 rounded-lg bg-black py-2"
        tabIndex={5}
      >
        <div
          className={`flex cursor-pointer items-center justify-between text-[${textColor}]`}
        >
          <p className="font-medium text-[1.1rem]">Dining Room</p>
          <ChevronDown />
        </div>
        <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
          <ul
            className={`list-square ml-4 md:ml-5 leading-loose md:leading-relaxed text-sm md:text-base text-[${textColor}]`}
          >
            <li>
              <b>Dust Surfaces:</b> Dust all surfaces, including tables and
              chairs.
            </li>
            <li>
              <b>Table Polishing:</b> Clean and polish the dining table.
            </li>
            <li>
              <b>Floor Care:</b> Vacuum or mop the dining room floor.
            </li>
            <li>
              <b>Baseboard Care:</b> Dust and clean baseboards.
            </li>
            <li>
              <b>Sanitize Touchpoints:</b> Clean and disinfect light switches
              and door handles.
            </li>
            <li>
              <b>Chair Cleaning:</b> Wipe down all chairs.
            </li>
          </ul>
        </div>
      </div>

      <div
        className="group flex flex-col gap-2 rounded-lg bg-black py-2"
        tabIndex={6}
      >
        <div
          className={`flex cursor-pointer items-center justify-between text-[${textColor}]`}
        >
          <p className="font-medium text-[1.1rem]">Entryway / Hallways</p>
          <ChevronDown />
        </div>
        <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
          <ul
            className={`list-square ml-4 md:ml-5 leading-loose md:leading-relaxed text-sm md:text-base text-[${textColor}]`}
          >
            <li>
              <b>Dust Surfaces:</b> Dust all surfaces in entryways and hallways.
            </li>
            <li>
              <b>Floor Care:</b> Vacuum or mop floors.
            </li>
            <li>
              <b>Sanitize Touchpoints:</b> Clean and disinfect light switches
              and door handles.
            </li>
            <li>
              <b>Wall and Door Cleaning:</b> Wipe down walls and doors.
            </li>
            <li>
              <b>Baseboard Care:</b> Dust and clean baseboards.
            </li>
            <li>
              <b>Organization:</b> Tidy and organize shoes and coats.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AccordionFAQ;
