import React from "react";
import Broom from '../assets/img/sweep-broom.jpeg';

class Banner extends React.PureComponent {
  render() {
    return (
      <section className="text-gray-600 body-font relative py-8 md:py-24">
        <div className="relative h-[30rem] md:h-[40rem] text-white overflow-hidden">
          <div className="absolute inset-0">
            <img src={Broom} alt="sweeping" className="object-cover object-center w-full h-full" />
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        
          <div className="w-full h-full backdrop-brightness-50 justify-start">
            <div className="p-4 md:ml-24 relative z-10 flex flex-col justify-center h-full place-items-start text-center">
              <h1 className="text-3xl md:text-4xl font-semibold leading-tight mb-4 text-[white]">Supplies: Safe & Effective</h1>
              <p className="text-[base] text-[white] mb-4 md:w-1/2 md:text-left">We make sure everything we use it clean your home does just that — without any damage to your furniture or items.</p>
              <p className="text-[base] text-[white] mb-4 md:w-1/2 md:text-left">We’ve tested hundreds of products to bring the highest level of clean to your home, while still being safe for the environment.</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;
