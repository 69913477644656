import React from "react";
import CleaningMirror from "../assets/img/cleaning-mirror.jpeg";

class CleaningPhoto extends React.PureComponent {
  render() {
    return (
      <div className="mt-16 md:mt-24">
        <img src={CleaningMirror} alt="Cleaning mirror" />
      </div>
    );
  }
}

export default CleaningPhoto;
