import React from "react";

type LogoProps = {
};

const Logo: React.FC<LogoProps> = () => {
  return (
    <svg
        viewBox="0 0 60 60"
        preserveAspectRatio="xMidYMid meet"
        className={`w-16 h-16`}
        id="efdbdbba"
      >
        <defs id="SvgjsDefs1011"></defs>
        <g
        id="SvgjsG1012"
        transform="matrix(0.8820564516129032,0,0,0.8820564516129032,0,0)"
        fill="#182443"
        >
        <path
            xmlns="http://www.w3.org/2000/svg"
            fill="#182443"
            fillRule="evenodd"
            d="M211.770903,227.529704 C212.823626,226.474383 214.471593,224.804894 214.913845,224.257147 L195.686292,203.313708 C192.706372,200.333789 191,196.299472 191,192 C191,183.163444 198.163444,176 207,176 C215.836556,176 223,183.163444 223,192 L223,199.5 L223,205.561728 C223,209.473765 223,209.473765 223,213 C223,217.059029 221.904121,220.701658 220.016909,223.902044 L234.473231,239.647377 C234.574297,239.757454 234.660701,239.875795 234.732546,240 L242.002752,240 C242.555506,240 243,240.45191 243,241.009369 L243,242.990631 C243,243.556647 242.552846,244 242.001254,244 L222.998746,244 C222.438615,244 222,243.54809 222,242.990631 L222,241.009369 C222,240.443353 222.44482,240 222.993532,240 L229.366895,240 L217.644191,227.231152 C217.22508,227.724616 215.774814,229.18554 214.719517,230.238012 C215.660257,231.318504 216.476744,232.540085 217.101555,233.925145 C219.06409,239.321813 215.780237,244 209.995066,244 L198.661197,244 C201.912662,241.436331 204,237.461928 204,233 C204,228.888046 202.227264,225.190137 199.404357,222.62884 C201.754858,222.942269 204.109324,223.433352 205.894427,224.211146 C207.807357,225.044635 209.876275,226.114261 211.770903,227.529704 L211.770903,227.529704 Z M190,244 C196.075132,244 201,239.075132 201,233 C201,226.924868 196.075132,222 190,222 C183.924868,222 179,226.924868 179,233 C179,239.075132 183.924868,244 190,244 Z M198.573736,200.546872 L217.159629,220.789992 C218.336708,218.44632 219,215.84956 219,213 C219,209.473765 219,209.473765 219,205.561728 C219,202.432099 219,202.432099 219,199.5 C219,193.625 219,193.625 219,192 C219,185.372583 213.627417,180 207,180 C200.372583,180 195,185.372583 195,192 C195,195.227702 196.277508,198.248071 198.573736,200.546872 Z"
            transform="translate(-179 -176)"
        ></path>
        </g>
    </svg>
  );
}

export default Logo;
