import { useEffect, useState } from "react";
import Logo from "../../assets/icons/Logo";
import ProgressBar from "../../components/ProgressBar";
import AppointmentForm from "../form/AppointmentForm";
import PaymentForm from "../form/PaymentForm";
import ChevronLeft from "../../assets/icons/ChevronLeft";
import { useAppointment } from "../../helper/AppointmentContext";
import Confirmation from "../form/Confirmation";

const PaymentModal = () => {
  const [finalStep, setFinalStep] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const { state, updateAppointment } = useAppointment();
  const [currentTotal, setCurrentTotal] = useState<string>('');

  const updateTotal = () => {
    let total = 0;
    if (state.cleaningService && state.services) {
      total += state.services[state.cleaningService].price;
    }
    if (state.selectedServices && state.additionalServices) {
      state.selectedServices.forEach((service: string) => {
        const selectedService = state.additionalServices.find((additionalService) => additionalService.name === service);
        if (selectedService) {
          if (selectedService.details.hasOwnProperty('count') && selectedService.details.count) {
            total += (selectedService.details.price * selectedService.details.count);
          } else {
            total += selectedService.details.price;
          }
        }
      });
    }

    const currency = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    const formattedCurrency = currency.format(total);    
    setCurrentTotal(formattedCurrency)
    updateAppointment('total', total);
  }

  
  useEffect(() => {
    updateTotal();
  }, [state.cleaningService, state.selectedServices, state.total])
  
  return (
    <div>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 flex items-center mb-4 md:mb-0">
            <Logo />
            <span className="ml-4 text-xl font-bold text-primary">
              Primetime Cleaners
            </span>
          </h3>

          {(finalStep && !isConfirmation) && (
            <div className="py-3 md:py-4">
              <button
                className="text-primary absolute left-2 md:left-6 top-[8rem] md:top-[7rem] font-semibold flex items-center"
                onClick={() => setFinalStep(false)}
              >
                <ChevronLeft />
                <span className="ml-2">Back</span>
              </button>
            </div>
          )}

          {!isConfirmation && <div className="mt-2 text-center mt-8">
            <div className="px-10 mb-6">
              <ProgressBar isFinalStep={finalStep} />
            </div>
            {state?.cleaningService && <h4 className="font-semibold text-2xl text-primary">
              {state.services[state.cleaningService].name}
            </h4>}
            {state.total && <p className="text-xl text-primary pt-2 font-semibold">{currentTotal}</p>}
          </div>}
        </div>
      </div>
      {!finalStep && <AppointmentForm setFinalStep={setFinalStep} />}
      {(finalStep && !isConfirmation) && <PaymentForm setIsConfirmation={setIsConfirmation} />}
      {(finalStep && isConfirmation) && <Confirmation />}
    </div>
  );
};

export default PaymentModal;
