import { useState, useEffect } from "react";

interface IconProps {
    name: string;
}

const Icon: React.FC<IconProps> = ({ name }) => {
    const [IconComponent, setIconComponent] = useState<React.ComponentType | null>(null);

    useEffect(() => {
        const importIconComponent = async () => {
            try {
                const { default: Icon } = await import(`../assets/icons/${name}`);
                setIconComponent(() => Icon);
            } catch (error) {
                console.error('Error loading icon component:', error);
            }
        };
        importIconComponent();

        return () => {
            setIconComponent(null); 
        };
    }, [name]);

    return IconComponent ? <IconComponent /> : null;
};

export default Icon;