import React, { useState } from "react";
import Hero from "../components/Hero";
import HeroForm from "../components/HeroForm";
import CleaningPhoto from "../components/CleaningPhoto";
import Services from "../components/Services";
import FeaturedLogo from "../components/FeaturedLogo";
import TestimonialVideo from "../components/TestimonialVideo";
import Banner from "../components/Banner";
import Statistic from "../components/Statistic";
import ChecklistFAQ from "../components/ChecklistFAQ";
import BudgetInfo from "../components/BudgetInfo";
import BookingForm from "../components/BookingForm";
import Location from "../components/Location";
import PaymentModal from "../common/Modal/PaymentModal";
import Modal from "../common/Modal";

const HomePage: React.FC<{}> = () => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  return (
    <>
      <Hero />
      <HeroForm setOpenPaymentModal={setOpenPaymentModal} />
      <CleaningPhoto />
      <Services setOpenPaymentModal={setOpenPaymentModal} />
      <FeaturedLogo />
      <TestimonialVideo />
      <Banner />
      <Statistic />
      <ChecklistFAQ />
      <BudgetInfo />
      <BookingForm setOpenPaymentModal={setOpenPaymentModal} />
      <Location />
      <Modal open={openPaymentModal} setOpen={setOpenPaymentModal}>
        <PaymentModal />
      </Modal>
    </>
  );
};

export default HomePage;
