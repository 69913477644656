import { createContext, ReactNode, useContext, useReducer } from 'react';

export interface ServiceOption {
    name: string;
    price: number;
    count?: number | null;
}

export interface ContactFormData {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    address: string;
    state: string;
    city: string;
    zipCode: string;
}

export interface AppointmentFormData extends ContactFormData {
  appointmentDate: string;
    appointmentTime: string;
    message?: string;
    services: { [key: string]: ServiceOption };
    cleaningService: string;
    selectedServices: string[];
    additionalServices: { name: string; details: ServiceOption }[];
    card: any;
    buyer: any;
    total: string;
    isBookLater: boolean;
}

type AppointmentAction = | { type: 'UPDATE_APPOINTMENT'; payload: { key: keyof AppointmentFormData; value: any } };

export const defaultState: AppointmentFormData = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: '',
    state: '',
    city: '',
    zipCode: '',
    appointmentDate: '',
    appointmentTime: '',
    message: '',
    services: {
      standardCleaning: { name: 'Standard Cleaning', price: 199.00 },
      deepCleaning: { name: 'Deep Cleaning', price: 349.00 },
      moveOutInCleaning: { name: 'Move Out/In Cleaning', price: 399.00 },
    },
    cleaningService: '',
    selectedServices: [],
    additionalServices: [
    //   { name: 'Standard', details: { name: 'Standard Cleaning', price: 199.00 } },
    //   { name: 'DeepCleaning', details: { name: 'Deep Cleaning', price: 349.00 } },
    //   { name: 'MoveOutCleaning', details: { name: 'Move Out/In Cleaning', price: 399.00 } },
      { name: 'OvenCleaning', details: { name: 'Oven Cleaning', price: 30.00, count: 1 } },
      { name: 'FridgeCleaning', details: { name: 'Fridge Cleaning', price: 70.00, count: 1 } },
      { name: 'WindowCleaning', details: { name: 'Full Window Cleaning', price: 250.00, count: 1 } },
      { name: 'Laundry', details: { name: 'Laundry & Folding', price: 75.00 } },
      { name: 'DishCleaning', details: { name: 'Dishes', price: 50.00 } },
      { name: 'Organize', details: { name: 'Organization', price: 150.00 } },
    ],
    buyer: {},
    card: {},
    total: '0.00',
    isBookLater: false,
};

export const appointmentReducer = (state: AppointmentFormData, action: AppointmentAction): AppointmentFormData => {
    switch (action.type) {
        case 'UPDATE_APPOINTMENT':
            return {
                ...state,
                [action.payload.key]: action.payload.value
            };
        default:
            return state;
    }
};

export interface AppointmentContextType {
    state: AppointmentFormData;
    updateAppointment: (key: keyof AppointmentFormData, value: any) => void;
}
  
export const AppointmentContext = createContext<AppointmentContextType | undefined>(undefined);

export const AppointmentProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(appointmentReducer, defaultState);
    
    const updateAppointment = (key: keyof AppointmentFormData, value: any) => {
        dispatch({ type: 'UPDATE_APPOINTMENT', payload: { key, value } });
    };

    return (
        <AppointmentContext.Provider value={{ state, updateAppointment }}>
            {children}
        </AppointmentContext.Provider>
    );
};

export const useAppointment = () => {
    const context = useContext(AppointmentContext);
    if (!context) {
        throw new Error('useAppointment must be used within an AppointmentProvider');
    }
    return context;
};