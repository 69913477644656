import React from "react";
import Yelp from "../assets/img/yelp-black.png";
import Google from "../assets/img/google-black.png";
import TrustPilot from "../assets/img/trust-pilot-black.png";
import Tribute from "../assets/img/san-diego-tribute.png";

class FeaturedLogo extends React.PureComponent {
  render() {
    return (
      <section className="text-gray-600 body-font">
        <div className="container py-8 md:py-10 mx-auto">
          <p className="text-center font-medium mb-6 md:mb-14">
            As Featured in
          </p>
          <div className="items-center grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-0 text-center">
            <div className="p-4 w-full px-12 md:px-24">
              <img src={Google} alt="yelp" />
            </div>
            <div className="p-4 w-full px-12 md:px-24">
              <img src={Yelp} alt="yelp" />
            </div>
            <div className="p-4 w-full px-10 md:px-20">
              <img src={TrustPilot} alt="yelp" />
            </div>
            <div className="p-4 w-full px-8 md:px-20">
              <img src={Tribute} alt="yelp" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FeaturedLogo;
