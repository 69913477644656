import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Calendar from "../../components/Calendar";
import TimeDropdown from "../../components/TimeDropdown";
import AdditionalServices from "../../components/AdditiontalServices";
import {
  AppointmentFormData,
  useAppointment,
} from "../../helper/AppointmentContext";
import { AppointmentSchema } from "../../helper/YupResolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../Loader";

interface AppointmentFormProps {
  setFinalStep: (finalStep: boolean) => void;
  onSubmit?: (data?: any) => void;
  buttonText?: string;
  isLoading?: boolean;
}

const AppointmentForm: React.FC<AppointmentFormProps> = ({
  setFinalStep,
  onSubmit,
  buttonText,
  isLoading = false,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AppointmentFormData>({
    resolver: yupResolver(AppointmentSchema) as any,
  });
  const { updateAppointment } = useAppointment();

  const onNext: SubmitHandler<AppointmentFormData> = (data) => {
    updateAppointment("message", data.message);
    onSubmit ? onSubmit(data) : setFinalStep(true);
  };

  const NextButton = (
    <button
      type="submit"
      className="mt-2 inline-flex text-[white] bg-primary-50 border-0 py-4 px-6 w-full focus:outline-none hover:bg-primary text-sm md:text-[1.25rem] font-bold justify-between"
    >
      {buttonText ? buttonText : <span>Next</span>}
      <span>⟶</span>
    </button>
  );

  return (
    <form onSubmit={handleSubmit(onNext)}>
      <div className="px-2 md:px-4 mb-10">
        <Calendar register={register} setValue={setValue} />
        {errors.appointmentDate && (
          <p className="text-red-600 text-sm ml-8">
            {errors.appointmentDate.message}
          </p>
        )}

        <TimeDropdown register={register} setValue={setValue} />
        {errors.appointmentTime && (
          <p className="text-red-600 text-sm ml-8 mb-6">
            {errors.appointmentTime.message}
          </p>
        )}

        <div className="mx-4 md:mx-8 mb-20">
          <AdditionalServices register={register} setValue={setValue} />
        </div>

        <div className="mx-4 md:mx-8 mb-10">
          <label className="relative font-semibold">Message (Optional)</label>
          <textarea
            {...register("message")}
            id="message"
            rows={2}
            className="block p-2.5 w-full text-base text-gray-900 bg-gray-50 border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-primary-500"
          ></textarea>
        </div>

        <div className="mx-4 md:mx-8">
          {!isLoading && NextButton}
          {isLoading && <Loader />}
        </div>
      </div>
    </form>
  );
};

export default AppointmentForm;
