import React from "react";
import ContactForm from "../common/form/ContactForm";
import Telephone from "../assets/icons/Telephone";
import Mail from "../assets/icons/Mail";
import Location from "../assets/icons/Location";

interface BookingFormProps {
  setOpenPaymentModal: (open: boolean) => void;
}

const BookingForm: React.FC<BookingFormProps> = ({ setOpenPaymentModal }) => {
  return (
    <section className="text-primary 0 body-font">
      <div className="container  px-10 py-8 md:py-24 mx-auto flex flex-wrap flex-col-reverse md:flex-row items-center">
        <div className="md:w-1/2 md:p-8 md:ml-auto w-full mt-10 md:mt-0">
          <ContactForm setOpenPaymentModal={setOpenPaymentModal} />
        </div>
        <div className=" md:w-1/2 md:pl-24">
          <h1 className="leading-snug font-medium text-[2rem] md:text-3xl text-primary w-3/4 mb-8 md:mb-0">
            Book The Old Fashioned Way.
          </h1>
          <div className="py-4">
            <div className="flex items-center">
              <Location />
              <p className="leading-relaxed ml-4 text-xl md:text-2xl font-medium w-full">
                San Diego, CA
              </p>
            </div>
          </div>
          <div className="py-4">
            <a
              href="tel:+16195650641"
              className="leading-relaxed text-xl md:text-2xl font-medium w-full flex items-center"
            >
              <Telephone />
              <span className=" ml-4">(619) 565-0641</span>
            </a>
          </div>
          <div className="py-4">
            <a
              href="mailto:info@apexcleaners.space"
              className="leading-relaxed text-xl md:text-2xl font-medium w-full flex items-center"
            >
              <Mail />
              <span className="ml-4 ">info@apexcleaners.space</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookingForm;
