import Link from "../helper/Link";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { EmailSchema } from "../helper/YupResolvers";
import { useState } from "react";

const Footer = () => {
  const [confirmation, setConfirmation] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EmailSchema)
  });

  const onSubmit = (data: any) => {
    setConfirmation(true);    
  };

  const Form = (
    <form className="justify-center items-end md:justify-start"  onSubmit={handleSubmit(onSubmit)}>
      <div className="relative">
        <label
          htmlFor="email"
          className="leading-7 text-sm text-primary"
        >
          Sign up to receive updates, subscription offers and alerts on
          limited time deals.
        </label>
        <input
          type="email"
          {...register("email", {
            required: "Email is required",
            pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" },
          })}
          placeholder="Email (required)"
          className="border-b-2 border-gray-300 focus:border-primary outline-none w-full py-4"
        />
        {errors.email && (
          <span className="text-red-600 text-sm">{errors.email.message}</span>
        )}
      </div>
      <button
        type="submit"
        className="mt-6 inline-flex text-[white] bg-primary-50 border-0 py-4 px-6 w-full focus:outline-none hover:bg-primary text-sm md:text-xl] font-bold justify-between"
      >
        <span>Submit</span>
        <span>⟶</span>
      </button>
    </form>
  )

  return (
    <footer className="text-primary body-font">
      <div className="container px-6 py-8 md:py-24 mx-auto">
        <div className="flex flex-wrap order-first md:justify-between flex-col-reverse md:flex-row">
          <div className="px-0 md:px-4 mt-16 md:mt-0">
            <h2 className="font-medium text-primary text-2xl md:text-3xl mb-3">
              Book Services
            </h2>
            <nav className="list-none mb-10 leading-[2.5rem]">
              <li>
                <Link
                  to="services"
                  className="text-primary hover:text-primary-50 cursor-pointer"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="booking"
                  className="text-primary hover:text-primary-50 cursor-pointer"
                >
                  Book Now
                </Link>
              </li>
              <li>
                <a
                  className="text-primary hover:text-primary-50"
                  href="/privacy-policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="text-primary hover:text-primary-50"
                  href="/terms-conditions"
                >
                  Terms & Conditions
                </a>
              </li>
            </nav>
          </div>

          <div className="md:w-1/3 mt-16 md:mt-0">
            <h2 className="font-medium text-primary text-2xl md:text-3xl mb-3">
              {!confirmation
                ? 'Get Email Deals'
                : 'Thank You for Subscribing!'}
            </h2>
            {confirmation && <p className="text-base text-primary leading-relaxed">We're excited to share our latest updates and exclusive content with you!</p>}
            {!confirmation && Form}
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
          <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
            Copyright © 2024 All Rights Reserved
          </p>
          {/* <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                    <a className="text-gray-500" href="/">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                    </a>
                    <a className="ml-3 text-gray-500" href="/">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                    </a>
                    <a className="ml-3 text-gray-500" href="/">
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                    </a>
                    <a className="ml-3 text-gray-500" href="/">
                    <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                        <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                        <circle cx="4" cy="4" r="2" stroke="none"></circle>
                    </svg>
                    </a>
                </span> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
