import React from "react";
import Wiping from "../assets//img/wiping.jpeg";

class Statistic extends React.PureComponent {
  render() {
    return (
      <section className="text-primary">
        <div className="container pt-20 px-10 py-8 md:py-24 mx-auto flex flex-wrap justify-between">
          <div className="mt-auto mb-auto md:w-1/2 content-start">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-3xl md:text-4xl mb-2 text-primary leading-none w-full">
                House Cleaning Services You Can See and Feel
              </h1>
              <div className="leading-normal md:w-3/5 text-base mt-8">
                Thousands of happy customers trust Primetime Cleaners for their
                house cleaning service.
              </div>
            </div>
            <div className="grid grid-cols-2 gap-y-4 md:gap-y-10">
              <div className="p-4 w-full border-l border-gray-400">
                <h2 className="font-medium text-2xl md:text-3xl text-primary">
                  288
                </h2>
                <h3 className="leading-relaxed text-base md:text-xl font-medium">
                  Bookings This Month
                </h3>
              </div>
              <div className="p-4 w-full border-l border-gray-400">
                <h2 className="font-medium text-2xl md:text-3xl text-primary">
                  900+
                </h2>
                <h3 className="leading-relaxed text-base md:text-xl font-medium">
                  Active Clients
                </h3>
              </div>
              <div className="p-4 w-full border-l border-gray-400">
                <h2 className="font-medium text-2xl md:text-3xl text-primary">
                  Eco-Friendly
                </h2>
                <h3 className="leading-relaxed text-base md:text-xl font-medium">
                  Cleaning Solutions
                </h3>
              </div>
              <div className="p-4 w-full border-l border-gray-400">
                <h2 className="font-medium text-2xl md:text-3xl text-primary">
                  40
                </h2>
                <h3 className="leading-relaxed text-base md:text-xl font-medium">
                  Years Experience
                </h3>
              </div>
            </div>
          </div>
          <div className="w-full md:w-5/12 overflow-hidden mt-6 sm:mt-0 h-[30rem] md:h-[40rem]">
            <img
              className="object-cover object-center w-full h-full"
              src={Wiping}
              alt="wiping"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Statistic;
