import React from 'react';

class TestimonialVideo extends React.PureComponent {
    render() {
        return (
            <section className="text-gray-600 body-font overflow-hidden">
                <div className="container px-10 py-8 md:py-24 mx-auto">
                    <div className="mx-auto flex flex-wrap items-center">
                        <iframe  src="https://www.youtube.com/embed/Squ9hSImFn0?si=JCHTSEkLYVy0_ExH" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" className='w-full md:w-5/12 h-96'></iframe>
                    <div className="w-full md:w-7/12 md:pl-14 lg:py-6 mt-6 lg:mt-0">
                        <h1 className="text-gray-900 text-2xl md:text-[3rem] font-medium mb-8 leading-tight">“I use Primetime every 2 weeks. They are the best in San Diego PERIOD! The best quality and amazing prices. 10/10”</h1>
                        <h4 className="font-medium text-xl md:text-2xl">– Angelica L</h4>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TestimonialVideo;