import React from 'react';

interface LoaderProps {
    className?: string;
}

const Loader: React.FC<LoaderProps> = ({
    className
}) => {
    return (
        <button type="button" className={`mt-6 inline-flex text-[white] bg-primary-50 border-0 py-3.5 md:py-4 px-6 w-full focus:outline-none hover:bg-primary text-sm md:text-[1.25rem] font-bold justify-center items-center cursor-not-allowed ${className}`} disabled>
           <svg className="animate-spin h-6 w-6 md:h-7 md:w-7 mr-4 text-color" viewBox="0 0 24 24" fill="none" stroke="currentColor" width="26" height="26">
                <circle className="opacity-75" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
            </svg>
            Processing...
        </button>
    );
};

export default Loader;