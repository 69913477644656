import React, { useEffect, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { AppointmentFormData, useAppointment } from '../helper/AppointmentContext';
import AdditionalItem from './AddtionalItem';

interface CalendarProps {
    register: UseFormRegister<AppointmentFormData>;
    setValue:  UseFormSetValue<AppointmentFormData>;
}

const AdditionalServices: React.FC<CalendarProps> = ({ register, setValue }) => {
    const { state, updateAppointment } = useAppointment();
    const [selectedServices, setSelectedServices] = useState<string[]>([]);

    const handleCheckboxChange = (serviceName: string) => {
        const updatedSelectedServices = state.selectedServices.includes(serviceName)
            ? state.selectedServices.filter((service: string) => service !== serviceName)
            : [...state.selectedServices, serviceName];
        updateAppointment("selectedServices", updatedSelectedServices);
    };

    useEffect(() => {
        if (state.selectedServices) {
            setSelectedServices(state.selectedServices);
            setValue("selectedServices", state.selectedServices);
        }
    }, [setValue, state.selectedServices])

    return (
        <>
            <h1 className="text-xl font-semibold">Additional Services</h1>
            <div className='mt-6 grid grid-cols-2 md:grid-cols-3 gap-x-8 gap-y-16 md:gap-y-14 justify-items-center'>
                {state.additionalServices.map((service: any) => {
                    const isActive = state.selectedServices.includes(service.name);
                    const hasCount = service.details.hasOwnProperty('count');
                    return (
                       <AdditionalItem 
                            key={service.name} 
                            register={register} 
                            service={service} 
                            isActive={isActive} 
                            hasCount={hasCount} 
                            handleCheckboxChange={handleCheckboxChange}                     
                       />
                    )}
                )}
            </div>
        </>
    );
};

export default AdditionalServices;