import React from "react";
class PrivacyPolicy extends React.PureComponent {
  render() {
    return (
      <div className="max-w-[40rem] mx-auto px-6 md:px-0">
        <h2 className="text-3xl font-bold text-center mb-10">Privacy Policy</h2>

        <ul className="list-decimal">
          <li>
            <strong>Introduction</strong>
            <p className="pb-2">
              At Primetime Cleaners, we are committed to protecting your privacy
              and ensuring that your personal information is handled in a safe
              and responsible manner. This Privacy Policy outlines how we
              collect, use, and protect your information.
            </p>
          </li>

          <li className="pt-4">
            <strong>Information We Collect</strong>
            <p className="pb-2">
              We collect the following types of information to provide and
              improve our services:
            </p>
            <ul className="list-disc leading-loose ml-4">
              <li>
                <strong>Personal Information</strong>
                <ul className="list-circle ml-4">
                  <li>
                    <strong>Contact Information:</strong> Name, address, phone
                    number, email address.
                  </li>

                  <li>
                    <strong>Payment Information:</strong> Credit card details,
                    billing address.
                  </li>
                </ul>
              </li>

              <li>
                <strong>Non-Personal Information</strong>
                <ul className="list-circle ml-4">
                  <li>
                    <strong>Usage Data:</strong> Information about how you
                    interact with our website and services, including IP
                    addresses, browser type, and access times.
                  </li>

                  <li>
                    <strong>Cookies: </strong>Small data files stored on your
                    device to enhance your experience on our website.
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <strong>How We Use Your Information</strong>
            <p className="pb-2">
              We use your information for the following purposes:
            </p>
            <ul className="list-disc leading-loose ml-4">
              <li>
                <strong>Service Provision: </strong>To provide and manage our
                cleaning services.
              </li>

              <li>
                <strong>Customer Support:</strong> To respond to your inquiries
                and provide support.
              </li>

              <li>
                <strong>Payment Processing:</strong> To process payments for our
                services.
              </li>

              <li>
                <strong>Marketing:</strong> To send you promotional materials
                and updates, with your consent.
              </li>

              <li>
                <strong>Improvement:</strong> To improve our services and
                website based on your feedback and usage patterns.
              </li>
            </ul>
          </li>

          <li className="pt-4">
            <strong>Sharing Your Information</strong>
            <p className="pb-2">
              We do not sell your personal information to third parties. We may
              share your information with:{" "}
            </p>
            <ul className="list-disc leading-loose ml-4">
              <li>
                <strong>Service Providers:</strong> Third-party companies that
                provide services on our behalf, such as payment processing and
                marketing.
              </li>

              <li>
                <strong>Legal Requirements:</strong> If required by law or to
                protect our rights and property.
              </li>
            </ul>
          </li>

          <li className="pt-4">
            <strong>Your Rights</strong>
            <p className="pb-2">
              Under the California Consumer Privacy Act (CCPA), California
              residents have specific rights regarding their personal
              information:
            </p>
            <ul className="list-disc leading-loose ml-4">
              <li>
                <strong>Right to Know:</strong> You have the right to request
                details about the personal information we collect, use, and
                disclose.
              </li>

              <li>
                <strong>Right to Delete:</strong> You have the right to request
                the deletion of your personal information, subject to certain
                exceptions.
              </li>

              <li>
                <strong>Right to Opt-Out:</strong> You have the right to opt-out
                of the sale of your personal information (Note: We do not sell
                your personal information).
              </li>

              <li>
                <strong>Right to Non-Discrimination:</strong> We will not
                discriminate against you for exercising your privacy rights.
              </li>
            </ul>
          </li>

          <li className="pt-4">
            <strong>Exercising Your Rights</strong>
            <p className="pb-2">
              To exercise your rights under the CCPA, please contact us using
              the information provided below. We will verify your identity
              before processing your request to ensure the security of your
              information.
            </p>
          </li>

          <li className="pt-4">
            <strong>Data Security</strong>
            <p className="pb-2">
              We implement appropriate technical and organizational measures to
              protect your personal information from unauthorized access,
              disclosure, alteration, or destruction. However, no security
              system is impenetrable, and we cannot guarantee the absolute
              security of your information
            </p>
          </li>

          <li className="pt-4">
            <strong>Children’s Privacy</strong>
            <p className="pb-2">
              Our services are not directed to individuals under the age of 13.
              We do not knowingly collect personal information from children
              under 13. If we become aware that we have collected personal
              information from a child under 13, we will take steps to delete
              such information.
            </p>
          </li>

          <li className="pt-4">
            <strong>Changes to This Privacy Policy</strong>
            <p className="pb-2">
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. We will notify you
              of any material changes by posting the new Privacy Policy on our
              website and updating the effective date.
            </p>
          </li>

          <li className="pt-4">
            <strong>Contact Us</strong>
            <p className="pb-2">
              If you have any questions or concerns about this Privacy Policy or
              our privacy practices, please contact us at:
            </p>
          </li>
        </ul>

        <div className="leading-loose">
          <p>
            <strong>Phone:</strong>{" "}
            <a href="tel:+16195650641" className="text-primary-50">
              (619) 565-0641
            </a>
          </p>
          <p>
            <strong>Email:</strong>{" "}
            <a
              href="mailto:info@apexcleaners.space"
              className="text-primary-50"
            >
              info@apexcleaners.space
            </a>
          </p>
          <p>
            <strong>Location:</strong> San Diego, CA
          </p>
          <p className="mt-6">
            <strong>Effective Date</strong>
          </p>
          <p>This Privacy Policy is effective as of January 1st, 2024.</p>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
